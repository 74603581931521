@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }
  }

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

.normal {
    font-family: "Montserrat", sans-serif;
}

.heading{
  font-family: "Bricolage Grotesque", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zeyada-regular {
  font-family: "Zeyada", cursive;
  font-weight: 400;
  font-style: normal;
}

input {
  -moz-appearance: textfield;
  appearance: textfield;
}

input:focus, textarea:focus {
  outline: none;
  border: 1px solid #E5AC00;
}

select {
  background-color: #fff;
}

/* custom.css */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}


/* Define the keyframes for the blink animation */
@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

/* Create a class that uses the blink animation */
.blink {
    animation: blink 2s infinite;
}

.react-international-phone-input-container .react-international-phone-input {
  display: block !important;
  width: 100% !important;
  padding: 15px !important;
  border: 1px solid #7d7d7d !important;
  font-size: 16px !important;
  font-family: Montserrat !important;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
    padding: 15px !important;
    border: 1px solid #7d7d7d !important;
}

.react-international-phone-input-container .react-international-phone-country-selector-button:focus, .react-international-phone-input-container .react-international-phone-input:focus{
    outline: none !important;
    border: 1px solid #E5AC00 !important;

}
.dots {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.dots div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #00e52e;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


/* Custom Scrollbar Styles */
.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #d1d5db #f3f4f6; /* Scrollbar thumb and track for Firefox */
}

/* WebKit-based browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* Width of the vertical scrollbar */
  height: 12px; /* Height of the horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f3f4f6; /* Scrollbar track background */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d1d5db; /* Scrollbar thumb color */
  border-radius: 10px;
  border: 2px solid #f3f4f6; /* Border around the thumb to provide padding */
}

/* Hover States */
.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #9ca3af; /* Darker gray on hover */
}

.custom-scrollbar:hover::-webkit-scrollbar-track {
  background-color: #e5e7eb; /* Lighter gray track on hover */
}

/* Active State (when dragging) */
.custom-scrollbar::-webkit-scrollbar-thumb:active {
  background-color: #6b7280; /* Even darker gray when scrolling */
}

/* Scrollbar Corner - for when both vertical and horizontal scrollbars are present */
.custom-scrollbar::-webkit-scrollbar-corner {
  background-color: #f3f4f6; /* Same as the track color */
}

/* Handle Edge Cases: Disabled scrollbars */
.custom-scrollbar:disabled::-webkit-scrollbar {
  display: none; /* Hide the scrollbar when scrolling is disabled */
}

/* Handle Scrollbar visibility when content is short */
.custom-scrollbar {
  overflow-y: auto; /* Ensure scrolling is possible */
}
